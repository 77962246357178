body {
  &.background-image {
    // background: url(https://farm3.staticflickr.com/2067/2062861571_d7384e2326_b.jpg) !important;
    background-repeat: no-repeat !important;
    background-position: center center !important;
    background-attachment: fixed !important;
    -webkit-background-size: cover !important;
    -moz-background-size: cover !important;
    -o-background-size: cover !important;
    background-size: cover !important;
    // backdrop-filter: blur(8px);
    #main.container {
      > .trove-menu,
      // .segment ,
      > .side-menu {
        background-color: rgba(255, 255, 255, 0.9) !important;
        backdrop-filter: blur(8px);
      }
    }
  }
}

$color_muted: rgb(150, 150, 150);

.ui.segments.dragging {
  opacity: 0.5 !important;
}

.ui.grid .row .column.dropping {
  background-color: rgb(220, 220, 220) !important;
}

.header,
h1,
h2,
h3,
h4,
h5,
h6,
p {
  &.muted {
    color: $color_muted !important;
  }
}
// .ui.card {
//   .content .header {
//     color: $color_muted !important;
//     cursor: pointer;
//   }
//   &.done.active {
//     .content .header {
//       color: #55b75c !important;
//     }
//   }
//   &.active:not(.done) {
//     .content .header {
//       color: #333 !important;
//     }
//   }
//   &:hover:not(.active),
//   &.hover:not(.active) {
//     // box-shadow: 0 1px 6px 0 #d4d4d5, 0 0 0 4px #d4d4d5 !important;
//     box-shadow: 0 0 0 1px #d4d4d5, 0 4px 0 0 rgb(33, 133, 208),
//       0 1px 3px 0 #c2c2c4 !important;
//   }
//   &.active {
//     // box-shadow: 0 1px 6px 0 #d4d4d5, 0 0 0 4px #d4d4d5 !important;
//     box-shadow: 0 0 0 1px #d4d4d5, 0 4px 0 0 rgb(33, 133, 208),
//       0 1px 8px 0 #c2c2c4 !important;
//   }
// }

table.emoji-shield {
  background-color: white;
  padding: 10px;
  border-radius: 10px;
  box-shadow: inset 0 -2px 0 #d1d5da;
  .emoji-shield-row {
    // display: inline-flex;
    .emoji-shield-col {
      font-size: 2.5em;
      line-height: 1em;
    }
  }
}

.kbds {
  > ::after {
    content: " or ";
  }
  > :last-child::after {
    content: "";
  }
}

kbd {
  display: inline-block;
  padding: 3px 5px;
  font: 12px SFMono-Regular, Consolas, Liberation Mono, Menlo, monospace;
  line-height: 12px;
  // color: #444d56;
  vertical-align: middle;
  background-color: #fafbfc;
  border: 1px solid #d1d5da;
  border-radius: 3px;
  box-shadow: inset 0 -1px 0 #d1d5da;
}

.item-content {
  word-break: break-word;
}

// .trove-item:not(.active),
// .trove-item-flat:not(.active) {
//   &:hover {
//     background: inherit !important;
//   }
// }

.float-item {
  &:not(.troved):hover {
    &:not(.dragging) {
      background-color: inherit !important;
      cursor: inherit !important;
    }
  }
}

.ui.list .item.plan-item .content .header {
  font-weight: normal !important;
}

.trove-item,
.plan-item {
  color: #333333 !important;

  &.funnel {
    color: #333333 !important;
    // font-weight: 600 !important;
  }
  &.dragging {
    opacity: 0.5;
  }
  // &.inactive {
  //   color: rgba(0, 0, 0, 0.5) !important;
  // }
}

.plan-item,
.trove-item {
  &.dropping:not(.dropping-child) {
    border-top: 2px solid rgba(33, 133, 208, 1) !important;
  }

  &.dropping-child {
    background-color: rgba(33, 133, 208, 0.2) !important;
  }

  &.positive {
    background: #fcfff5 !important;
    color: #2c662d !important;
  }

  &.editing {
    background: #cce2ff !important;
  }
}

.trove-item-flat {
  &.ghost {
    opacity: 0.3;
  }
}

// body {
//   background-color: rgb(250, 250, 250) !important;
// }

$workspace_menu_width: 0;
$trove_menu_width: 250px;
$side_menu_width: 380px;
$grey: #f3f4f5;
$grey_border: rgb(220, 220, 220);

#main.container {
  > .trove-menu,
  > .main,
  > .side-menu {
    padding-top: 20px;

    // opacity: $inactive_opacity;
    &:focus {
      // opacity: 1;
      outline: none;
    }
  }

  .trove-menu,
  .side-menu {
    padding-bottom: 30px;
  }

  > .trove-menu,
  > .side-menu,
  > .main {
    position: fixed;
    // display: flex;
    flex-direction: column;
    top: 44px;
    bottom: 0;
    padding-left: 18px;
  }

  > .trove-menu {
    left: $workspace_menu_width;
    width: $trove_menu_width;
    background-color: $grey !important;
    border-right: 1px solid rgb(220, 220, 220) !important;
    overflow-x: hidden;
    overflow-y: scroll;
  }

  > .side-menu {
    display: none;
    right: 0;
    // padding-left: 30px;
    padding-right: 10px;
    width: $side_menu_width;
    border-left: 1px solid rgb(220, 220, 220) !important;
    background-color: $grey !important;
    overflow-x: hidden;
    overflow-y: scroll;
  }

  // >.workspace-menu {
  //   left: 0;
  //   width: $workspace_menu_width;
  //   background-color: rgb(27, 28, 29) !important;
  // }
  > .trove-menu::-webkit-scrollbar-track,
  > .side-menu::-webkit-scrollbar-track,
  > .main::-webkit-scrollbar-track {
    background-color: transparent;
  }

  > .main {
    left: $workspace_menu_width + $trove_menu_width;
    right: 0;
    padding-left: 30px;
    padding-right: 30px;
    padding-bottom: 100px;
    overflow: scroll;
  }

  &.side-menu-visible {
    > .side-menu {
      display: inline;
    }

    > .main {
      right: $side_menu_width;
    }
  }
}

.breadcrumb {
  &.muted .section {
    color: $color_muted !important;
  }

  .section {
    max-width: 150px;

    &:last-child {
      max-width: 250px;
    }
  }
}

.overflow-trimmed {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ui.steps .step {
  padding-top: 0.5em !important;
  padding-bottom: 0.5em !important;

  .icon {
    font-size: 1.5em !important;
  }
}

.ui.selection.list .list > .item.selected,
.ui.selection.list > .item.selected {
  background-color: rgba(33, 133, 208, 0.2) !important;
}

.item.trove-item,
.item.plan-item {
  position: relative;
}

.floated.floated-absolute {
  position: absolute;
  background-color: white;

  &.right {
    right: 0.5em;
    top: 0.5em;
  }
}
